.btn-green-g {
  text-shadow: rgba(37, 66, 3, 0.8) 0 1px 2px;
  background-color: var(--color-poly-b);
  background-image: linear-gradient(
    90deg,
    var(--color-poly-b),
    var(--color-poly-b-light)
  );
  border-color: var(--color-poly-b-light);
}

.btn-orange {
  background-color: var(--color-poly-o);

  &:hover {
    background-color: var(--color-poly-e);
  }

  &:active {
    background-color: var(--color-poly-o) !important;
  }
}

.btn-orange-g {
  text-shadow: rgba(92, 37, 0, 0.8) 0 1px 2px;
  background-color: var(--color-poly-c);
  background-image: linear-gradient(
    90deg,
    var(--color-poly-c),
    var(--color-poly-e)
  );
  border-color: var(--color-poly-c);

  &:disabled {
    color: var(--color-mono-f) !important;
    text-shadow: none;
    background-color: var(--color-mono-e);
    background-image: none;
    border-color: var(--color-mono-e);
  }
}

.btn-white-on-blue {
  background-color: var(--color-gradient-blue-a);
}

.cta {
  width: 280px;
}
