@use "@angular/material" as mat;

@import "assets/stylesheets/angular-material-colors.scss";

@include mat.all-legacy-component-typographies();
@include mat.legacy-core();

$moura-facil-primary: mat.define-palette($moura-blue-2);
$moura-facil-accent: mat.define-palette($moura-yellow-1);
$moura-facil-warn: mat.define-palette(mat.$red-palette);

$moura-facil-theme: mat.define-light-theme(
  (
    color: (
      primary: $moura-facil-primary,
      accent: $moura-facil-accent,
      warn: $moura-facil-warn,
    ),
  )
);

@include mat.all-legacy-component-themes($moura-facil-theme);

@import "assets/stylesheets/colors.scss";

@import "assets/stylesheets/bootstrap-variables.scss";
@import "assets/stylesheets/bootstrap.scss";

:root {
  @extend %baseTheme;
}

@media (prefers-color-scheme: dark) {
  :root {
    @extend %baseTheme;
  }
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: var(--color-poly-q);
}

.min-content {
  min-height: calc(100vh - 424px);
}

a {
  color: $primary;
}

strong {
  font-weight: 500;
}

input,
textarea,
button {
  font-family: inherit;
}

@import "assets/stylesheets/bootstrap-custom.scss";
@import "assets/stylesheets/backgrounds.scss";
@import "assets/stylesheets/buttons.scss";

@import "assets/stylesheets/canvas-confetti.scss";
@import "assets/stylesheets/google-maps.scss";

@import "assets/stylesheets/to-remove.scss";
