.pac-container {
  z-index: 2;
  border: var(--color-mono-e) solid 1px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.075) 0 0.125rem 0.25rem;
}

.pac-item {
  padding: 12px 16px;
  color: var(--color-mono-d);
  font-family: var(--font-roboto);
  font-size: 16px;
  line-height: 24px;
  border-top: 0;
  border-bottom: var(--color-mono-e) dashed 1px;
  transition: 0.3s background-color;

  @media (hover: hover) {
    &:hover {
      background-color: var(--color-mono-l);
    }
  }

  &:active {
    background-color: var(--color-mono-l-dark);
  }

  &:last-child {
    border-bottom: 0;
  }
}

.pac-icon {
  margin-top: 3px;
  margin-right: 6px;
}

.pac-item-query {
  font-size: 16px;
}

.pac-matched {
  font-weight: 500;
  background-color: var(--color-brand-yellow);
}
