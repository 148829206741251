// -------------------------------------------------------------------------- //
// Remover depois que o Bootstrap for importado completamente
// -------------------------------------------------------------------------- //

.container,
.container-fluid,
[class*="col"] {
  box-sizing: border-box;
}

.btn-lg {
  font-size: 18px;
  font-weight: 500;

  @media (max-width: 429px) {
    font-size: 16px;
  }
}

.text-right {
  text-align: right;
}

.form-floating {
  > label {
    display: inline-block;
    color: var(--color-mono-n);
    font-size: 1rem;
    line-height: 1.2;
  }

  > .form-control {
    &:focus ~ label:after,
    &:not(:placeholder-shown) ~ label:after {
      height: 1.2em;
      background-color: white;
    }
  }

  > input.form-control {
    min-height: 52px;
    height: 52px;
  }

  > textarea.form-control {
    min-height: 104px;
    height: 104px;
  }
}

.form-control {
  box-sizing: border-box;
}

.form-prefix {
  position: absolute;
  top: 25px;
  left: 14px;
  font-size: 15.5px;
}

textarea {
  resize: vertical;
}

.form-text {
  font-size: 0.75em;
}

// -------------------------------------------------------------------------- //
// End Remover depois que o Bootstrap for importado completamente
// -------------------------------------------------------------------------- //
