.bg-blue-t {
  background-color: rgba(0, 19, 64, 0.9);
}

.bg-blue-g {
  background-color: var(--color-gradient-blue-a);
  background-image: linear-gradient(
    145deg,
    var(--color-gradient-blue-a),
    var(--color-gradient-blue-b)
  );
}

.bg-orange {
  background-color: var(--color-poly-o);
}

.bg-orange-g {
  background-color: var(--color-poly-c);
  background-image: linear-gradient(
    90deg,
    var(--color-poly-c),
    var(--color-poly-e)
  );
}
