// CSS para remover quando tivermos nos livrado de vez do Angular Material
.mat-typography,
.mat-typography h2 {
  line-height: initial;
}

.mat-form-field {
  &.mat-form-field-appearance-outline {
    .mat-form-field-wrapper {
      margin-top: 0 !important;
      border-radius: 6px;

      .mat-form-field-outline {
        background-color: #fff;
      }
    }
  }
}

.mat-expansion-panel-header,
.mat-expansion-panel-body {
  @media (min-width: 768px) {
    padding-right: 32px !important;
    padding-left: 32px !important;
  }
}

.border-box {
  box-sizing: border-box;
}

.list-unstyled {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  list-style-type: none;
}
