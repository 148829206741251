%baseTheme {
  --color-brand-blue: #001489;
  --color-brand-cyan: #0077c8;
  --color-brand-yellow: #ffbf3f;
  --color-gradient-blue-a: #124594;
  --color-gradient-blue-b: #536fef;
  --color-mono-d: #474747;
  --color-mono-e: #cfcfcf;
  --color-mono-f: #4a4a4a;
  --color-mono-g: #9b9b9b;
  --color-mono-h: #eaeaea;
  --color-mono-j: #333;
  --color-mono-k: #efefef;
  --color-mono-l-dark: #e0e0e0;
  --color-mono-l: #fbfbfb;
  --color-mono-n: #777;
  --color-poly-a: #4a87d4;
  --color-poly-b-light: #5ea807;
  --color-poly-b: #417505;
  --color-poly-c: #f76300;
  --color-poly-e: #fc8c08;
  --color-poly-h: #ffc510;
  --color-poly-o: #fe6504;
  --color-poly-p: #4a87d4;
  --color-poly-q: #001949;
  --color-social-facebook: #1877f2;
  --color-social-instagram: #c13584;
  --color-social-linkedin: #0077b5;
  --color-social-twitter: #000000;
  --color-social-whatsapp: #075e54;
  --color-social-youtube: #ff0000;
  --font-roboto: Roboto, "Helvetica Neue", sans-serif;
}

.text-orange {
  color: var(--color-poly-o);
}

.text-yellow {
  color: yellow;
}
